<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div>
            <h1>Affiliate Sales for 2024</h1>

            <!-- Export to CSV Button -->
            <button @click="exportToCSV" class="btn btn-primary">Export to CSV</button>

            <!-- Affiliate Monthly Sales Table -->
            <table class="sales-table">
              <thead>
              <tr>
                <th>Name</th>
                <th v-for="(month, index) in months" :key="index">{{ month }}</th>
                <th>Total Before</th>
                <th>Total After</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="affiliate in affiliates"
                  :key="affiliate.affiliate.id"
                  @click="toggleUsers(affiliate)"
                  class="affiliate-row"
              >
                <td :class="getAffiliateClass(affiliate)" style="color:white !important">
                  {{ affiliate.affiliate.first_name }} {{ affiliate.affiliate.last_name }}
                  <button @click.stop="exportAffiliateUsersToCSV(affiliate)" class="btn btn-secondary">
                    Export Users to CSV
                  </button>
                  <template v-if="affiliate.showUsers">
                    <div class="user-info">
                      <strong>Users:</strong>
                      <table class="user-table">
                        <thead>
                        <tr>
                          <th style="color:black !important">User Name</th>
                          <th style="color:black !important" v-for="(month, index) in months" :key="index">{{ month }}</th>
                          <th style="color:black !important">Total Before</th>
                          <th style="color:black !important">Total After</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="user in affiliate.users" :key="user.id" class="user-row">
                          <td style="color:black !important">{{ user.first_name }} {{ user.last_name }}</td>
                          <td style="color:black !important" v-for="(sales, monthIndex) in Object.entries(user.monthly_sales)" :key="monthIndex">
                            {{ formatCurrency(sales[1].before) }} / {{ formatCurrency(sales[1].after) }}
                          </td>
                          <td style="color:black !important">{{ formatCurrency(getTotalUserSales(user, 'before')) }}</td>
                          <td style="color:black !important">{{ formatCurrency(getTotalUserSales(user, 'after')) }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </td>
                <td style="color:black !important" v-for="(sales, monthIndex) in Object.entries(affiliate.monthly_sales)" :key="monthIndex">
                  {{ formatCurrency(sales[1].before) }} / {{ formatCurrency(sales[1].after) }}
                </td>
                <td style="color:black !important">{{ formatCurrency(getTotalSales(affiliate.monthly_sales, 'before')) }}</td>
                <td style="color:black !important">{{ formatCurrency(getTotalSales(affiliate.monthly_sales, 'after')) }}</td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      affiliates: [],
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      currentMonth: new Date().getMonth() + 1, // Get the current month (1-12)
    };
  },
  methods: {
    fetchAffiliateSales() {
      axiosInstance.get('api/affiliates-invoices', { params: { from: '2024-01-01', to: '2024-12-31' } })
          .then(response => {
            console.log('API Response:', response.data); // Log the response data
            this.affiliates = response.data.map(affiliate => ({
              ...affiliate,
              showUsers: false // Initialize showUsers property
            }));
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    },
    toggleUsers(affiliate) {
      affiliate.showUsers = !affiliate.showUsers;
    },
    formatCurrency(value) {
      return `$${parseFloat(value).toFixed(2)}`;
    },
    getAffiliateClass(affiliate) {
      return affiliate.users.length > 1 ? 'multiple-users' : 'single-user';
    },
    getTotalSales(monthlySales, type) {
      return Object.values(monthlySales).reduce((total, sales) => total + sales[type], 0);
    },
    getTotalUserSales(user, type) {
      return Object.values(user.monthly_sales).reduce((total, sales) => total + sales[type], 0);
    },
    exportToCSV() {
      let csvContent = '\uFEFF' + 'Name,' + this.months.join(',') + ',Total Before,Total After\n'; // Add BOM for UTF-8

      this.affiliates.forEach(affiliate => {
        const row = [
          `${affiliate.affiliate.first_name} ${affiliate.affiliate.last_name}`,
          ...this.months.map((month, index) => {
            const monthIndex = index + 1; // Backend uses 1-based indexing for months
            const sales = affiliate.monthly_sales[monthIndex] || { before: 0, after: 0 };
            return `${sales.before.toFixed(2)} / ${sales.after.toFixed(2)}`;
          }),
          this.getTotalSales(affiliate.monthly_sales, 'before').toFixed(2),
          this.getTotalSales(affiliate.monthly_sales, 'after').toFixed(2),
        ].join(',');

        csvContent += row + '\n';
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'affiliate_sales.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportAffiliateUsersToCSV(affiliate) {
      let csvContent = '\uFEFF' + 'User Name,' + this.months.join(',') + ',Total Before,Total After\n'; // Add BOM for UTF-8

      affiliate.users.forEach(user => {
        const row = [
          `${user.first_name} ${user.last_name}`,
          ...this.months.map((month, index) => {
            const monthIndex = index + 1; // Backend uses 1-based indexing for months
            const sales = user.monthly_sales[monthIndex] || { before: 0, after: 0 };
            return `${sales.before.toFixed(2)} / ${sales.after.toFixed(2)}`;
          }),
          this.getTotalUserSales(user, 'before').toFixed(2),
          this.getTotalUserSales(user, 'after').toFixed(2),
        ].join(',');

        csvContent += row + '\n';
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${affiliate.affiliate.first_name}_${affiliate.affiliate.last_name}_users.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    this.fetchAffiliateSales();
  }
};
</script>


<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
}

.sales-table {
  width: 100%; /* Full width of the parent */
  border-collapse: collapse;
  margin-top: 10px;
}

.sales-table th,
.sales-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better spacing */
  text-align: left;
}

.sales-table th {
  background-color: #f2f2f2;
}

.affiliate-row {
  cursor: pointer; /* Change cursor to pointer for clickable rows */
  background-color: #ffffff; /* Default background for affiliates */
}

.affiliate-row:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

.user-row {
  background-color: #f9f9f9; /* Slightly different background for user rows */
}

.user-info {
  padding-left: 20px; /* Indentation for user info */
  padding-top: 10px; /* More padding for visual separation */
  padding-bottom: 10px; /* More padding for visual separation */
  border-top: 1px solid #ddd; /* Optional: add a top border for clarity */
}

.user-info strong {
  font-weight: bold; /* Bold user names */
}

.user-info span {
  margin-left: 15px; /* Space between month sales info */
}

/* Style for affiliate with multiple users */
.multiple-users {
  background-color: #00aa00; /* Red background if more than 1 user */
  color: black; /* White text for contrast */
}

/* Style for affiliate with only one user */
.single-user {
  background-color: #aa0000; /* Green background if only 1 user */
  color: black; /* White text for contrast */
}
</style>
