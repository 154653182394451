
<template>
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <title>Gocami</title>
  </head>

  <body>
    <router-view/>

  </body>
</template>

<script>
export default {
  data() {
    return {
      loginSuccess: false, // Control the visibility of the success message
    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.loginSuccess) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },
};
</script>

<style>
/* General Styles */
.full-width-cover {
  position: fixed; /* Ensures it stays over everything */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the viewport without distortion */
}

/* Mobile-Specific Design */
.mobile-cover-image {
  position: relative; /* No need for fixed on mobile */
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin-top: 100px; /* Adjust spacing for better view if needed */
}

/* Hide second image on larger screens */
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

@media (max-width: 768px) {
  .full-width-cover {
    display: none;
  }
}

/* Adjust typography for mobile */
* {
  font-size: 10pt !important;
  font-weight: 600 !important;
}

sup {
  font-size: 10pt !important;
}
</style>
