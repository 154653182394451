<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div class="filter-section mb-3">
            <label for="from" class="me-2">From:</label>
            <input
                type="date"
                class="form-control w-25 d-inline-block me-3"
                v-model="filterFrom"
                @change="fetchCommissions"
            />
            <label for="to" class="me-2">To:</label>
            <input
                type="date"
                class="form-control w-25 d-inline-block"
                v-model="filterTo"
                @change="fetchCommissions"
            />
          </div>
          <table class="table table-striped w-100">
            <thead>
            <tr>
              <th>Affiliate ID</th>
              <th>Affiliate Name</th>
              <th>Total Commission</th>
              <th>Details</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="affiliate in commissions" :key="affiliate.user_id">
              <!-- Display Affiliate Details -->
              <tr>
                <td>{{ affiliate.user_id }}</td>
                <td>{{ affiliate.first_name }} {{ affiliate.last_name }}</td>
                <td>{{ affiliate.total_commission_status_0 }}</td>
                <td>
                  <button
                      class="btn btn-sm btn-primary"
                      @click="toggleAffiliateDetails(affiliate.user_id)"
                  >
                    {{ isAffiliateDetailsVisible(affiliate.user_id) ? 'Hide' : 'Show' }}
                  </button>
                </td>
              </tr>
              <!-- Display Sub-Affiliates -->
              <tr v-if="isAffiliateDetailsVisible(affiliate.user_id)">
                <td colspan="4">
                  <h6>Sub-Affiliates</h6>
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th>Sub ID</th>
                      <th>Sub Name</th>
                      <th>Commission</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="sub in affiliate.sub_affiliates"
                        :key="sub.id"
                    >
                      <td>{{ sub.id }}</td>
                      <td>{{ sub.first_name }} {{ sub.last_name }}</td>
                      <td>{{ sub.commission }}</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axiosInstance from '../../../axiosInstance.js';

export default {
  setup() {
    const commissions = ref([]);
    const filterFrom = ref(null);
    const filterTo = ref(null);
    const visibleAffiliates = ref({}); // Track visibility of affiliates

    // Fetch commissions data
    const fetchCommissions = () => {
      const params = {
        from: filterFrom.value,
        to: filterTo.value,
      };

      axiosInstance
          .get('api/sub-affiliate-full-medal', { params })
          .then((response) => {
            commissions.value = response.data;
          })
          .catch((error) => {
            console.error('Error fetching affiliate commissions:', error);
          });
    };

    // Toggle visibility of affiliate details
    const toggleAffiliateDetails = (affiliateId) => {
      visibleAffiliates.value[affiliateId] =
          !visibleAffiliates.value[affiliateId];
    };

    // Check visibility
    const isAffiliateDetailsVisible = (affiliateId) => {
      return visibleAffiliates.value[affiliateId] || false;
    };

    onMounted(() => {
      fetchCommissions();
    });

    return {
      commissions,
      filterFrom,
      filterTo,
      fetchCommissions,
      toggleAffiliateDetails,
      isAffiliateDetailsVisible,
    };
  },
};

</script>

<style scoped>
.affiliate-name {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
</style>
